<!-- 文件中文名: 订购历史 -->
<template>
  <div class="order-list app-container">
    <!--    order-list-->
    <p style="text-align: left;font-size: 30px">{{ $t('Order.record') }}</p>
    <!--          确认收货-->
    <el-button :loading="doLoading" icon="el-icon-check" size="small" style="float: left" type="primary"
               @click="buttonensureRec()">{{ $t('ensureRec') }}
    </el-button>
    <el-table
        :key="tableKey"
        v-loading="doLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
        height="600"
        @selection-change="handleSelectionChange"
    >
      <el-table-column :selectable="selectable" align="center" type="selection" width="45"/>
      <!-- 操作 -->
      <el-table-column :label="$t('sysOperationLog.moduleName1')" align="center" class-name="small-padding fixed-width"
                       width="200">
        <template slot-scope="{row}">
          <!--          查看-->
          <el-button :loading="doLoading" :title="$t('button.view')" icon="el-icon-search" size="small"
                     @click="handleView(row)"/>
          <!--          补款-->
          <el-button v-show="row.status=== '0'" :loading="doLoading" plain size="small" type="success"
                     @click="payMoney(row)">
            <i class="el-icon-circle-plus-outline"></i></el-button>
          <!--          删除-->
          <el-button v-if="row.status === '0'&&row.orderType!=21"
                     :loading="doLoading" :title="$t('button.delete')" icon="el-icon-close"
                     size="small" type="danger"
                     @click="buttondelete(row)"/>
        </template>
      </el-table-column>
      <!--     添加日期 -->
      <el-table-column :label="$t('date.added1')" align="center" prop="createTime" width="80"/>
      <!--    订单号No  -->
      <el-table-column :label="$t('order.no')" align="center" prop="orderNo" width="90"/>
      <!--    买家  -->
      <el-table-column :label="$t('buyer1')" align="center" prop="memberName" width="90"/>
      <!--    货品数量  -->
      <!--      <el-table-column :label="$t('Quantity.goods')" prop="rightQty" align="center" />-->
      <!--     订单类型 -->
      <el-table-column :label="$t('pd.orderType')" align="center" prop="status">
        <template slot-scope="{row}">
          <span>{{ $lt(initDict.ordertype_rus, row.orderType) }}</span>
        </template>
      </el-table-column>
      <!--     状态 -->
      <el-table-column :label="$t('Common.status')" align="center" prop="status">
        <template slot-scope="{row}">
          <span>{{ $lt(initDict.paystate, row.status) }}</span>
        </template>
      </el-table-column>
      <!--     收货状态 -->
      <el-table-column :label="$t('pd.recStatus')" align="center" prop="status">
        <template slot-scope="{row}">
          <span>{{ $lt(initDict.pdrecstatus, row.recFlag) }}</span>
        </template>
      </el-table-column>
      <!--      发货状态-->
      <el-table-column :label="$t('ai.sendinfo')" align="center" prop="status">
        <template slot-scope="{row}">
          <div v-if="row.recType == 1 && row.agentSendStatus == 0">
            <!-- 未发货 -->
            <span>{{$t('ca.pdsendinfo.orderflag.n')}}</span>
          </div>
          <div v-if="row.recType == 1 && row.agentSendStatus == 1">
            <!-- 未发货 -->
            <span v-if="row.sendStatusTwo == 0">{{$t('ca.pdsendinfo.orderflag.n')}}</span>
            <!-- 部分发货 -->
            <span v-if="row.sendStatusTwo == 1">{{$t('po.send.part')}}</span>
            <!-- 全部发货 -->
            <span v-if="row.sendStatusTwo == 2">{{$t('po.send.all')}}</span>
          </div>
          <div v-if="row.recType != 1">
            <!-- 未发货 -->
            <span v-if="row.sendStatusTwo == 0">{{$t('ca.pdsendinfo.orderflag.n')}}</span>
            <!-- 部分发货 -->
            <span v-if="row.sendStatusTwo == 1">{{$t('po.send.part')}}</span>
            <!-- 全部发货 -->
            <span v-if="row.sendStatusTwo == 2">{{$t('po.send.all')}}</span>
          </div>
        </template>
      </el-table-column>
      <!--    合计积分  -->
      <el-table-column :label="$t('order.pv.total')" align="center" prop="totalH0">
        <template slot-scope="{row}">
          <span v-if="row.orderType==='19'">{{ row.totalH0 }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!--    合计金额  -->
      <el-table-column :label="$t('saFiIncomeReport.title.total')" align="center" prop="totalAmount"/>
      <!--     奖金钱包 -->
<!--      <el-table-column :label="$t('bonus.wallet')" prop="totalFb" align="center" />-->
    </el-table>
    <pagination
        v-show="total>0"
        :limit.sync="listQuery.limit"
        :page.sync="listQuery.page"
        :total="total"
        @pagination="getList"
    />
    <div style="float: right;margin: 20px 0">
      <el-button :loading="doLoading" plain size="mini" type="warning"
                 @click="continueBtn">{{ $t('btn.continue') }}
      </el-button>
    </div>
    <el-dialog
        class="dialogVisible"
        :before-close="handleClose"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :title="$t('Supplementary.payment')"
        :visible.sync="dialogVisible"
        width="70%">
      <div>
        <div v-show="!payRes">
          <el-form ref="payForm" :model="payForm" label-position="right" size="small">
            <el-form-item :label="$t('sysUser.password2')+':'" required>
              <el-input v-model="payForm.payPassword" show-password></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div v-show="payRes">
          <el-result :title="$t('notice.payment.success')" icon="success"/>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="doLoading" plain type="primary" @click="upDatePayForm">{{ $t('Pay.Immediately') }}</el-button>
        <el-button :loading="doLoading" plain type="danger"
                   @click="closeDialog">{{ $t('operation.button.close') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {memberdelete, memberGoonOrder, memberOrderRecs, orderList} from "@/api/rus/api"
import Pagination from '@/components/Pagination'
import {mapGetters} from "vuex";

export default {
  name: 'OrderList',
  directives: {},
  components: {Pagination},
  data() {
    return {
      initDict: {
        ordertype_rus: 'po.ordertype_rus',
        paystate: 'sysaftersale.paystate',
        pdrecstatus: 'pd.recstatus',
        aisendinfo: 'ai.sendinfo',
      },
      listQuery: {
        page: 1,
        limit: 20
      },
      selectTableData: [],
      tableKey: 0,
      list: [],
      payForm: {
        // payH0: '',
        // payAmount: '',
        payPassword: ''
      },
      rowData: {},
      payRes: false,
      dialogVisible: false,
      doLoading: false,
      total: 0,
      listLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'userCode',
    ])
  },
  watch: {},
  created() {
    this.getList()
  },
  mounted() {
  },
  methods: {
    handleView(row) {
      this.$router.push({path: 'orderlist-view', query: {id: row.id}})
    },
    getList() {
      this.doLoading = true
      orderList({ ...this.listQuery}).then(res => {
        this.list = res.data.records
        this.total = +res.data.total
        this.doLoading = false
      }).catch(err => {
        console.error(err)
        this.doLoading = false
      })
    },
    payMoney(row) {
      this.rowData = row
      this.dialogVisible = true


        // this.$confirm(this.$t('fi.sure.handle'), this.$t('common.tips'), {
        //     confirmButtonText: this.$t('button.confirm'),
        //     cancelButtonText: this.$t('operation.button.cancel'),
        //     customClass: 'msgBox',
        //     type: 'warning'
        // }).then(() => {
        //     this.doLoading = true
        //     memberGoonOrder({id: row.id}).then(res => {
        //         if (res.success) {
        //             this.getList()
        //             this.$message.success(this.$t('notice.payment.success').toString())
        //         }
        //         this.doLoading = false
        //     }).catch(err => {
        //         console.error(err)
        //         this.doLoading = false
        //     })
        // }).catch(() => {
        //     this.$message({
        //         type: 'info',
        //         message: this.$t('operation.button.cancel')
        //     })
        //     this.doLoading = false
        // })

    },
    buttondelete(row) {
      this.$confirm(this.$t('fi.sure.handle'), this.$t('common.tips'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('operation.button.cancel'),
        customClass: 'msgBox',
        type: 'warning'
      }).then(() => {
        this.btnloading = true
        memberdelete({'id': row.id}).then(() => {
          this.btnloading = false
          this.getList()
          this.$message.success(this.$t('bdSendRegister.operaterSuccess'))
        }).catch(err => {
          this.btnloading = false
          console.error(err)
          this.$message.error(this.$t('bdSendRegister.operaterFail'))
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('operation.button.cancel')
        })
        this.btnloading = false
      })
    },
    //确认收货按钮
    buttonensureRec() {
      this.$confirm(this.$t('fi.sure.handle'), this.$t('common.tips'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('operation.button.cancel'),
        customClass: 'msgBox',
        type: 'warning'
      }).then(() => {
        this.doLoading = true
        memberOrderRecs(this.selectTableData).then(() => {
          this.getList()
          this.doLoading = false
        }).catch(err => {
          console.error(err)
          this.doLoading = false
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('operation.button.cancel')
        })
        this.btnloading = false
      })
    },
    handleSelectionChange(val) {
      this.selectTableData = []
      for (let i = 0; i < val.length; i++) {
        this.selectTableData = this.selectTableData.concat(val[i].id)
      }
    },
    selectable(row) {
      // recFlag收货状态                未收货0  已收货1
      // recType发货状态                代办处发货1 到代办处自提2 到分公司自提3 发到经销商4
      // sendStatusTwo 发货状态         未发货0  部分发货1  全部发货2
      // agentSendStatus代办处发货状态   未发货0            全部发货1
      return this.userCode === row.memberNo && row.recFlag !== 1 && (((row.recType === 1 || row.recType === 2) && row.sendStatusTwo === 2  && row.agentSendStatus === 1) || ((row.recType === 3|| row.recType === 4) && row.sendStatusTwo === 2))
    },
    handleClose(done) {
      this.$confirm(this.$t('milink.isClose'),{
        customClass: 'msgBox',
      })
          // eslint-disable-next-line no-unused-vars
          .then(_ => {
            this.payForm = {
              payH0: '',
              payAmount: '',
              payPassword: ''
            }
            done();
          })
          // eslint-disable-next-line no-unused-vars
          .catch(_ => {
          });
    },
    upDatePayForm() {
      // if (this.rowData.orderType === '19' && isNaN(this.payForm.payH0)) {
      //   return this.$message.error('支付积分只能输入数字')
      // }
      // if (isNaN(this.payForm.payAmount)) {
      //   return this.$message.error('支付金额只能输入数字')
      // }
      this.doLoading = true
      memberGoonOrder({...this.payForm, id: this.rowData.id}).then(res => {
        if (res.success) {
          this.payForm = {
            payH0: '',
            payAmount: '',
            payPassword: ''
          }
          this.dialogVisible = false
          this.getList()
          this.$message.success(this.$t('notice.payment.success').toString())
        }
      }).catch(err => {
        console.error(err)
        this.doLoading = false
      })
    },
    closeDialog() {
      this.payForm = {
        payH0: '',
        payAmount: '',
        payPassword: ''
      }
      this.dialogVisible = false
    },
    continueBtn() {
      this.getList()
    }
  }
}

</script>

<style scoped>
.font-color-red {
  color: red;
}
</style>
